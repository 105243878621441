const typography = {
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px',
    },
    fontFamily: [
      'Roboto',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontStyle: 'normal',
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px',
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px',
    },
    h4: {
      fontWeight: 700,
      fontSize: 20,
      letterSpacing: '-0.06px',
    },
    h5: {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: '-0.05px',
    },
    h6: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '-0.05px',
    },
    overline: {
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: '-0.05px',
      color: '#546E7A',
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '-0.04px',
    },
    body2: {
      fontSize: 12,
      fontWeight: 300,
      letterSpacing: '0.2px',
    },
  }
  
  export default typography
  