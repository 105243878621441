import React from 'react'

import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

import useStyles from './styles'

import Logo from 'images/logo_branco.png'

const TopBar = ({ loginWithButton = () => {} }) => {
  const classes = useStyles()

  return (
    <AppBar className={classes.appBar} elevation={2}>
      <Toolbar className={classes.toolbar}>
        <Container>
          <Box className={classes.root}>
            <img src={Logo} alt="logo-horizontal" className={classes.logo} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box ml={2} pr={5} my={2}>
                <Button
                  variant="contained"
                  onClick={() => loginWithButton()}
                  size="small"
                  className={classes.button}
                >
                  <Box
                    display="flex"
                    className={classes.buttonBox}
                    alignItems="center"
                  >
                    <Box pr={0.5} display="flex" alignItems="center">
                      <FontAwesomeIcon
                        className={classes.buttonText}
                        icon={faSignInAlt}
                      />
                    </Box>
                    <Typography className={classes.buttonText}>
                      Acessar
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
