export const getBaseURL = (project) => envsBaseUrl[project][env]

const env = process.env.REACT_APP_API || 'development'

const companyToken = {
  development: '8b710b32d01428c1d5cc55b95b2d23bd',
  sandbox: '8b710b32d01428c1d5cc55b95b2d23bd',
  production: '34990fb9004ad5c64ca3d94ca9ce0348',
}

export const COMPANY_TOKEN_LOGIN_WITH_ID = companyToken[env]

const envsBaseUrl = {
  smartRevision: {
    development: 'https://api.unimar.sandbox.leavening.com/',
    sandbox: 'https://api.unimar.sandbox.leavening.com/',
    // production: 'https://api.unimar.sandbox.leavening.com/',
    production: 'https://api.partners.parceiros.smartRevision.com.br',
  },
  auth: {
    development: 'https://auth.sandbox.oneid.com.br/auth.js',
    sandbox: 'https://auth.sandbox.oneid.com.br/auth.js',
    production: 'https://auth.oneid.com.br/auth.js',
  },
  oneId: {
    development: 'https://app.sandbox.oneid.com.br',
    sandbox: 'https://app.sandbox.oneid.com.br',
    production: 'https://app.oneid.com.br',
  },
}
