import { Box, Grid, Typography, Tooltip, makeStyles } from '@material-ui/core'

import clsx from 'clsx'

import styles from './styles'

const useStyles = makeStyles(styles)

const Label = ({
  children,
  ellipsis,
  title = '',
  description = '',
  className = '',
  titleVariant = 'subtitle2',
  marginTopChildren = 1,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid
      {...rest}
      className={clsx(
        {
          [classes.ellipsis]: ellipsis,
        },
        classes.root,
      )}
    >
      <Typography variant={titleVariant} style={{ color: '#006d9e' }}>
        {title}
      </Typography>
      <Tooltip noWrap title={description} placement="bottom-start">
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      </Tooltip>
      <Box mt={marginTopChildren}>{children}</Box>
    </Grid>
  )
}

export default Label
