import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, IconButton } from '@material-ui/core'
import { LoadingBox } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import moment from 'moment'
import { Edit as EditIcon } from 'react-feather'

import { routes } from 'Routes'

const TableRowEssay = ({
  essay,
  setLoading = () => {},
  onEvent = () => {},
  ...rest
}) => {
  const history = useHistory()

  const handleDetails = (essayId) => {
    history.push(
      reverse(routes.essay.view, {
        essayId: essayId,
      }),
    )
  }

  // const onChange = (value) => {
  //   setGrade(value)
  // }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{essay.id}</TableCell>
        <TableCell>{essay.theme}</TableCell>
        <TableCell>{essay.title}</TableCell>
        <TableCell>
          {moment(essay.created).format('DD/MM/YYYY HH:mm')}
        </TableCell>
        <TableCell align="right" disableTooltip>
          <IconButton color="inherit" onClick={() => handleDetails(essay.id)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}

const EssayTable = ({
  essays,
  isLoading,
  onEvent = () => {},
  setLoading = () => {},
}) => {
  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum serviço encontrado"
              isLoading={isLoading}
              style={{
                backgroundColor: '#fff',
              }}
              noWrap
            >
              <TableHead>
                <TableRow height={55}>
                  <TableCell variant="head">
                    <h3>Identificador</h3>
                  </TableCell>
                  <TableCell variant="head">
                    <h3>Tema</h3>
                  </TableCell>
                  <TableCell variant="head">
                    <h3>Título</h3>
                  </TableCell>
                  <TableCell variant="head">
                    <h3>Submissão</h3>
                  </TableCell>
                  <TableCell variant="head" align="right">
                    <h3>Ações</h3>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {essays?.map((essay) => (
                  <TableRowEssay
                    essay={essay}
                    key={essay.id}
                    setLoading={setLoading}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

export default EssayTable
