import PropTypes from 'prop-types'

import { Route } from 'react-router-dom'

import * as services from 'services'

const DefaultComponent = ({ children }) => <>{children}</>

const RouteWithLayout = ({
  auth,
  layout,
  provider,
  component: Component,
  permission,
  somePermissions,
  reduceMargin,
  admin = false,
  redirect = true,
  ...rest
}) => {
  const Layout = layout || DefaultComponent
  const Provider = provider || DefaultComponent
  const isAuthenticated = services.smartRevision.auth.isAuthenticated()

  if (isAuthenticated) {
    if (!auth) {
      services.history.push('/essay')
      return null
    }
  }

  const renderRoute = (matchProps) => {
    return (
      <Layout>
        <Provider component={Provider}>
          <Component {...matchProps} />
        </Provider>
      </Layout>
    )
  }

  return <Route {...rest} render={(matchProps) => renderRoute(matchProps)} />
}

RouteWithLayout.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  path: PropTypes.string,
  permission: PropTypes.string,
  admin: PropTypes.bool,
}

export default RouteWithLayout
