import { Switch } from 'react-router-dom'
import { RouteWithLayouts } from './components'
import { include } from 'named-urls'
import { Auth, Main } from 'layouts'
import { Login, Essay } from 'views'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayouts
        path={routes.root}
        component={Login}
        layout={Auth}
        exact
      />

      <RouteWithLayouts
        path={routes.essay.all}
        component={Essay.ListOfEssay}
        layout={Main}
        exact
        auth
      />

      <RouteWithLayouts
        path={routes.essay.view}
        component={Essay.OneEssay}
        layout={Main}
        exact
        auth
      />

      {/* <RouteWithLayout
        path={routes.user.account}
        component={Settings.Account}
        layout={MainLayout}
        exact
        auth
      /> */}
    </Switch>
  )
}

export const routes = {
  dataProcess: include('/data-processes', {
    all: '',
    view: 'show/:dataProcessId',
    new: 'new',
    edit: ':dataProcessId/edit',
    resume: 'resume',
    suggested: 'suggested',
  }),

  root: '/',
  essay: include('/essay', {
    all: '',
    view: 'show/:essayId',
    new: 'new',
    edit: ':essayId/edit',
  }),

  // user: include('/users', {
  //   all: '',
  //   new: 'new',
  //   edit: ':userId/edit',
  //   account: '/account',
  // }),
}

export default Routes
