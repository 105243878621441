import * as yup from 'yup'

export const schema = yup.object().shape({
  grade: yup
    .number()
    .typeError('Digite um número válido')
    .required()
    .min(0)
    .max(100),

  comment: yup.string().when('grade', {
    is: 0,
    then: yup.string().required(),
  }),
})

export default schema
