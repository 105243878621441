import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#000000'
const primaryMain = '#006EA1'
const primaryLight = '#F3FAFF'
const primaryHover = '#007EB7'
const secondaryDark = '#14171A'
const secondaryMain = '#657786'
const secondaryLight = '#AAB8C2'
const secondarySuperLight = '#E1E8ED'
const backgroundDefault = '#F4F6F8'

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: primaryMain,
    light: primaryLight,
    hover: primaryHover,
  },
  secondary: {
    contrastText: white,
    dark: secondaryDark,
    main: secondaryMain,
    light: secondaryLight,
    superLight: secondarySuperLight,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: secondaryDark,
    menu: secondaryMain,
    secondary: secondaryMain,
    link: colors.blue[600],
  },
  background: {
    default: backgroundDefault,
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[300],
}

export default palette
