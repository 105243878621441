import { Box, Typography, Divider, Grid, Paper } from '@material-ui/core'
import { Label } from 'components'

const EssayDetails = ({ essay, isLoading }) => {
  console.log(essay)
  return (
    <Box
      width="100%"
      style={{
        backgroundColor: '#fff',
        border: '1px solid #006d9e',
        borderRadius: '10px',
      }}
    >
      <>
        {!isLoading && (
          <>
            <Box m={2}>
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Título: {essay.title}
                </Typography>
              </Box>
            </Box>
            <Box px={2} py={2}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {/* <Label align="center" title={essay.title} titleVariant="h4"> */}
                  <Typography variant="h5" color="secondary" align="justify">
                    {essay.text}
                  </Typography>
                  {/* </Label> */}
                </Grid>
                <Divider />

                {essay.history?.map((essay, i) => (
                  <Grid item xs={2}>
                    <Box style={{ width: '125px' }}>
                      <Paper elevation={2}>
                        <Label
                          align="center"
                          title={`Histórico de notas ${i + 1}:`}
                          titleVariant="h6"
                        >
                          <Typography
                            variant="body1"
                            color="secondary"
                            align="center"
                          >
                            {essay.grade}
                          </Typography>
                        </Label>
                        <Label
                          align="center"
                          title="Comentário desta nota:"
                          titleVariant="h6"
                        >
                          <Typography
                            variant="body1"
                            color="secondary"
                            align="center"
                          >
                            {essay.comment}
                          </Typography>
                        </Label>
                      </Paper>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
      </>
    </Box>
  )
}

export default EssayDetails
