import { useState, useEffect } from 'react'

import AuthContext from 'contexts/AuthContext'

import { BookOpen as BookOpenIcon } from 'react-feather'

import * as services from 'services'

const AuthProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  // const [permissions, setPermissions] = useState(null)
  const [menuItems, setMenuItems] = useState([])
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    loadDataWithAnimation()
    // eslint-disable-next-line
  }, [])

  const loadData = async () => {
    // const [responseMe, responsePermissions] = await Promise.all([
    //   services.smartRevision.user.me(),
    // ])
    //   setUser(responseMe?.data?.user)

    const menuItems = [
      {
        subheader: 'Smart Revision',
        items: [
          // {
          //   title: 'Inicio',
          //   icon: HomeIcon,
          //   href: '/',
          // },
          {
            title: 'Dissertações',
            icon: BookOpenIcon,
            href: '/essay',
          },
        ],
      },
    ]
    setMenuItems(menuItems)
  }

  const loadUser = async () => {
    let response = await services.smartRevision.user.me()
    setUser(response?.data?.user)
    return response?.data?.user
  }

  const loadDataWithAnimation = async () => {
    setIsLoading(true)
    setLoaded(false)

    try {
      await loadData()
    } finally {
      setLoaded(true)
      setIsLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        setUser,
        loaded,
        isLoading,
        menuItems,
        loadDataWithAnimation,
        loadData,
        loadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
