import { TableEssay } from './components'
import useFetch from 'hooks/useFetch'
import * as services from 'services'
import { TablePagination, Container } from '@material-ui/core'
import usePagination from 'hooks/usePagination'
import { ContentHeader, Page } from 'components'

const ListOfEssay = () => {
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading } = useFetch(
    services.smartRevision.essay.get,
    {
      page,
    },
    [page],
  )
  return (
    <Page title="Dissertações">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Smart Revision"
          title="Lista de dissertações"
          subtitle="Lista de dissertações"
        />
        <TableEssay
          essays={response?.data?.results || []}
          isLoading={isLoading}
        />
        <TablePagination
          component="div"
          count={response?.data?.count}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[10]}
          labelRowsPerPage={'Por página'}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Container>
    </Page>
  )
}

export default ListOfEssay
