import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  logo: {
    width: '150px',
    height: '75px',
    padding: '20px',
  },
  appBar: {
    backgroundColor: '#005BAA',
    minHeight: '70px !important',
  },
  toolbar: {
    padding: '0px',
  },
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: '30px',
    paddingRight: '30px',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
  },
  button: {
    backgroundColor: '#005BAA',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 1px',
    borderColor: '#449FC9',
    borderRadius: '5px 5px 5px 5px',
    boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 50%)',
    '&:hover': {
      backgroundColor: '#005BAA',
    },
  },
  buttonText: {
    fontSize: '13px',
    color: '#FFF',
    fontWeight: 550,
    letterSpacing: '0.2px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  buttonBox: {
    padding: '3px 8px',
  },
}))

export default styles
