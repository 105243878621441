import {
  Link as RouterLink,
  useHistory,
  matchPath,
  useLocation,
} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import {
  AppBar,
  IconButton,
  Toolbar,
  SvgIcon,
  Box,
  makeStyles,
  Hidden,
} from '@material-ui/core'

import { Account } from './components'

import { ArrowLeft as ArrowLeftIcon } from 'react-feather'
import { Menu as MenuIcon } from 'react-feather'

import styles from './styles'
import Logo from 'images/logo_branco.png'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)
const TopBar = ({
  className,
  onMobileNavOpen = () => {},
  withSidebar = false,
  ...rest
}) => {
  const classes = useStyles()
  const [showBackButton, setShowBackButton] = useState(false)
  const history = useHistory()

  const handleReturn = () => {
    history.goBack()
  }

  const location = useLocation()

  useEffect(() => {
    const verifyMatch = (uri) => {
      if (!matchPath(location.pathname, { path: uri, exact: true })) {
        return setShowBackButton(false)
      }

      setShowBackButton(true)
    }
    verifyMatch('/essay/show/:essayId')
  }, [location.pathname])

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {showBackButton && (
            <IconButton color="inherit" onClick={handleReturn}>
              <ArrowLeftIcon />
            </IconButton>
          )}

          <Hidden mdDown>
            <RouterLink to={routes.home}>
              <img
                alt="Logo"
                src={Logo}
                className={classes.logo}
                width="100%"
              />
            </RouterLink>
          </Hidden>
          <Hidden lgUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>
        </Box>
        <Account />
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
