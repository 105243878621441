import axios from 'axios'
import humps from 'humps'
import qs from 'qs'
import { getBaseURL } from './env'
import * as services from './index'

import history from 'services/history'
import { routes } from 'Routes'

const create = (projectTag, treatError = true) => {
  let api = axios.create({ baseURL: getBaseURL(projectTag) })

  api.interceptors.request.use((reqConfig) => {
    const authToken = services.smartRevision.auth.getToken()
    if (authToken) {
      reqConfig.headers.Authorization = `JWT ${authToken}`
    }
    // reqConfig.headers.Authorization =
    //   'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjI4Nzg2NzcwLCJqdGkiOiI5ZDg2MGE1NTBmZTI0ZmRhYjgzNzZiMWNhZWVjMmQxZiIsInVzZXJfaWQiOjN9.af3y3926OxC6LYlvg7Q8rRi7vaf8UcXRXvSAWYpRVQI'
    if (process.env.REACT_APP_API === 'production') {
      reqConfig.headers.crossDomain = true
    }
    reqConfig.data = humps.decamelizeKeys(reqConfig.data)
    reqConfig.params = humps.decamelizeKeys(reqConfig.params)

    reqConfig.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      })
    }

    return reqConfig
  })

  api.interceptors.response.use(
    (resp) => humps.camelizeKeys(resp),
    (error) => {
      if (treatError) {
        if (error && error.response && 401 === error.response.status) {
          services.smartRevision.auth.logout()
          return Promise.reject(humps.camelizeKeys(error))
        }
        if (error?.response?.status === 404) {
          if (error?.params?.listAnswersOrigin) {
            history.push(routes.notFound)
            return Promise.reject(humps.camelizeKeys(error))
          }
        }

        if (error && error.response && error.response.status === 403) {
          history.push(routes.forbidden)
          return Promise.reject(humps.camelizeKeys(error))
        }
      }
      return Promise.reject(humps.camelizeKeys(error))
    },
  )

  return api
}

const api = {
  create,
}

export default api
