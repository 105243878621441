import {
  Box,
  Typography,
  Divider,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core'
import { Label } from 'components'

const styles = makeStyles(() => ({ root: { color: 'red' } }))

const EssayLogs = ({ essay, isLoading }) => {
  const classes = styles()
  return (
    <Box width="100%">
      <>
        {!isLoading && (
          <>
            <Box my={5}>
              <Typography
                style={{
                  alignItems: 'center',
                  fontWeight: 'bold',
                  paddingBottom: '15px',
                }}
                variant="h3"
              >
                Detalhes da Correção
              </Typography>
              <Box
                px={2}
                py={3}
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #006d9e',
                  borderRadius: '10px',
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} style={{ color: 'red' }}>
                    {essay?.apiResponse?.matches?.map((match) => (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            className={classes.root}
                            style={{ padding: '10px 0px' }}
                            variant="h6"
                          >
                            {`${match.message}: ${match.context.text}`}
                          </Typography>
                        </Box>
                        <Divider />
                      </>
                    ))}
                  </Grid>

                  <Grid item xs={3}>
                    <Paper elevation={3}>
                      <Label
                        title="Quantidade de erros:"
                        align="center"
                        titleVariant="h6"
                      >
                        <Typography
                          variant="body1"
                          style={{ color: 'red' }}
                          align="center"
                        >
                          {essay.erros}
                        </Typography>
                      </Label>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper elevation={3}>
                      <Label
                        align="center"
                        title="Número de palavras: "
                        titleVariant="h6"
                      >
                        <Typography variant="body1" align="center">
                          {essay.palavras}
                        </Typography>
                      </Label>
                    </Paper>
                  </Grid>

                  <Grid item xs={3}>
                    <Paper elevation={3}>
                      <Label
                        title="Palavras únicas: "
                        align="center"
                        titleVariant="h6"
                      >
                        <Typography variant="body1" align="center">
                          {essay.palavrasUnicas}
                        </Typography>
                      </Label>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper elevation={3}>
                      <Label
                        title="Número de palavras chaves: "
                        align="center"
                        titleVariant="h6"
                      >
                        <Typography variant="body1" align="center">
                          {essay.keywords}
                        </Typography>
                      </Label>
                    </Paper>
                  </Grid>

                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  ></Box>
                </Grid>
              </Box>
            </Box>
            <Typography
              style={{
                alignItems: 'center',
                fontWeight: 'bold',
                paddingBottom: '15px',
              }}
              variant="h3"
            >
              Composição de Notas
            </Typography>
            <Box
              px={2}
              py={3}
              style={{
                backgroundColor: '#fff',
                border: '1px solid #006d9e',
                borderRadius: '10px',
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <Paper elevation={3}>
                    <Label
                      title="Nota por palavras únicas: "
                      align="center"
                      titleVariant="h6"
                    >
                      <Typography variant="body1" align="center">
                        {essay.notaPalavrasUnicas}
                      </Typography>
                    </Label>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3}>
                    <Label
                      title="Nota por relação com o tema: "
                      titleVariant="h6"
                      align="center"
                    >
                      <Typography variant="body1" align="center">
                        {essay.notaKeywords}
                      </Typography>
                    </Label>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3}>
                    <Label
                      title="Nota por erros: "
                      align="center"
                      titleVariant="h6"
                    >
                      <Typography variant="body1" align="center">
                        {essay.notaErros}
                      </Typography>
                    </Label>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3}>
                    <Label
                      title="Nota por tamanho: "
                      align="center"
                      titleVariant="h6"
                    >
                      <Typography variant="body1" align="center">
                        {essay.notaPalavras}
                      </Typography>
                    </Label>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    <Label
                      title="Nota Final: "
                      align="center"
                      titleVariant="h6"
                    >
                      <Typography variant="body1" align="center">
                        {essay.notaFinal}
                      </Typography>
                    </Label>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </>
    </Box>
  )
}

export default EssayLogs
