import React from 'react'
import PropTypes from 'prop-types'

import { TableCell as TableCellMaterial, Box, Tooltip } from '@material-ui/core'

// import styles from './styles'

export const DEFAULT_BODY_SKELETON_HEIGHT = 20

const TableCell = ({
  bodySkeletonHeight = DEFAULT_BODY_SKELETON_HEIGHT,
  children,
  overFlowSize = '200px',
  disableTooltip = false,
  ...rest
}) => {
  // const useStyles = styles(overFlowSize)

  // const classes = useStyles()

  return (
    <TableCellMaterial {...rest}>
      <Tooltip
        disableFocusListener={disableTooltip}
        disableHoverListener={disableTooltip}
        disableTouchListener={disableTooltip}
        title={children}
      >
        <Box>{children}</Box>
      </Tooltip>
    </TableCellMaterial>
  )
}

TableCell.propTypes = {
  bodySkeletonHeight: PropTypes.number,
}

export default TableCell
