import api from 'services/api'

const smartRevisionAPI = api.create('smartRevision', false)

const me = async (data) => {
  return await smartRevisionAPI.get('/me', data)
}



const user = {
  me,
}

export default user
