import {
  EssayDetails,
  EssayLogs,
  EssayUpdateGrade,
  Page,
  ContentHeader,
} from 'components'
import useFetch from 'hooks/useFetch'
import * as services from 'services'
import { Container } from '@material-ui/core'

const OneEssay = ({ match }) => {
  const { essayId } = match.params
  const { response, isLoading } = useFetch(services.smartRevision.essay.get, {
    essayId,
  })
  const { response: responseLogs, isLoading: isLoadingLogs } = useFetch(
    services.smartRevision.essay.getLogs,
    {
      essayId,
    },
  )

  return (
    <>
      <Page title="Uma dissertação">
        <Container maxWidth={false}>
          <ContentHeader
            menu="Lista de dissertações"
            title="Dissertação"
            subtitle={`Dissertação ${essayId}`}
          />
          <EssayDetails essay={response?.data || {}} isLoading={isLoading} />
          <EssayLogs
            essay={responseLogs?.data[0] || {}}
            isLoading={isLoadingLogs}
          />
          <EssayUpdateGrade essayId={essayId} />
        </Container>
      </Page>
    </>
  )
}

export default OneEssay
