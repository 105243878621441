import api from 'services/api'

const smartRevisionAPI = api.create('smartRevision', false)

const get = async ({ essayId = '', ...params }) => {
  return await smartRevisionAPI.get(`/api/f1/essay/${essayId}`, { params })
}

const update = async ({ essayId = '', ...data }) => {
  return await smartRevisionAPI.put(`/api/f1/essay/${essayId}/grade/`, data)
}

const getLogs = async ({ essayId = '' }) => {
  return await smartRevisionAPI.get(`/api/f1/essay/${essayId}/logs`)
}

const essay = {
  get,
  update,
  getLogs,
}

export default essay
