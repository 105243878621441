import React from 'react'
import { useLocation, Router } from 'react-router-dom'
import { ThemeProvider, withStyles } from '@material-ui/styles'
import { createGenerateClassName } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { JssProvider } from 'react-jss'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'configs/yup/locale'
import { getBaseURL, COMPANY_TOKEN_LOGIN_WITH_ID } from 'services/env'

import theme from 'theme'
import history from 'services/history'
import AuthProvider from 'providers/AuthProvider'
import Routes from './Routes'
import SnackbarProvider from 'providers/SnackBarProvider'
import { Helmet } from 'react-helmet'
const defaultGenerateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'c',
})
const isTestEnv = ['development', 'sandbox'].includes(process.env.REACT_APP_API)

const App = () => (
  <>
    <Helmet
      script={[
        {
          type: 'text/javascript',
          src: getBaseURL('auth'),
          name: 'leavening_login_with',
          'data-name': 'env',
          env: isTestEnv ? 'sandbox' : process.env.REACT_APP_API,
        },
      ]}
    />

    <input
      type="hidden"
      id="leavening_company_token"
      value={COMPANY_TOKEN_LOGIN_WITH_ID}
    />

    <JssProvider generateClassName={defaultGenerateClassName}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
          <Router history={history}>
            <ScrollToTop />
            <SnackbarProvider>
              <AuthProvider>
                <Routes />
              </AuthProvider>
            </SnackbarProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </JssProvider>
  </>
)

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      height: '100%',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
}

function ScrollToTop() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default withStyles(styles)(App)
