import PropTypes from 'prop-types'
import { makeStyles, Box } from '@material-ui/core'
import { useState } from 'react'
import { TopBar } from 'components'
import styles from './styles'
import { NavBar } from './components'
const useStyles = makeStyles(styles)

const Main = ({ children }) => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  return (
    <Box className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} withSidebar />

      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Box className={classes.wrapper}>
        <Box className={classes.contentContainer}>
          <Box className={classes.content}>{children}</Box>
        </Box>
      </Box>
    </Box>
  )
}

Main.propTypes = {
  children: PropTypes.any,
}

export default Main
