import api from 'services/api'
import humps from 'humps'
import history from 'services/history'
import { AUTH_TOKEN } from 'services/constants'

const smartRevisionAPI = api.create('smartRevision', false)

const login = async (data) => {
  const response = await smartRevisionAPI.post('/authentication', data)
  const token = response.data.access

  setToken(token)

  return response
}

const setToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token)
}

const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN)
}

const logout = () => {
  history.push('/')
  localStorage.removeItem(AUTH_TOKEN)
}

const getUserIdFromToken = () => {
  const token = getToken()
  const payload = humps.camelizeKeys(JSON.parse(atob(token.split('.')[1])))

  return payload.userId
}

const isAuthenticated = () => {
  const token = getToken()
  return !!token
}

const auth = {
  login,
  logout,
  getToken,
  setToken,
  isAuthenticated,
  getUserIdFromToken,
}

export default auth
