import TopBar from './components/TopBar'
import * as services from 'services'
import useAuth from 'hooks/useAuth'
import { COMPANY_TOKEN_LOGIN_WITH_ID } from 'services/env'
import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import Logo from '../../images/logo.png'
const Login = () => {
  const snackbar = useSnackbar()
  const auth = useAuth()
  const history = useHistory()
  const loginWithButton = async () => {
    try {
      const response = await window.getTokenAuth()
      await services.smartRevision.auth.login({
        companyToken: COMPANY_TOKEN_LOGIN_WITH_ID,
        token: response?.token,
        companySubdomain: 'smartRevision',
      })
      auth.loadDataWithAnimation()
      history.push(routes.home)
    } catch (error) {
      if (error && error.response && 401 === error.response.status) {
        snackbar.open({
          message: 'Não autorizado!',
          variant: 'error',
        })

        return
      }
      if (error && error.response) {
        snackbar.open({
          message: 'Email ou senha inválidos',
          variant: 'error',
        })
        return
      }

      snackbar.open({
        message: 'Ocorreu algum erro! Tente novamente!',
        variant: 'error',
      })
    }
  }

  // const signUpButton = async () => {
  //   const response = await window.signUpOneID()
  //   snackbar.open({ message: response.message, variant: response.status })
  // }

  return (
    <div>
      <TopBar loginWithButton={loginWithButton} />
      <img src={Logo} alt="Minha Figura" />
    </div>
  )
}

export default Login
