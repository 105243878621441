import { makeStyles } from '@material-ui/core'

const styles = (height, cursor) => {
  return makeStyles((theme) => ({
    cursor: {
      cursor: cursor,
    },
    rowHeight: {
      height: height,
    },
  }))
}

export default styles
