import auth from './auth'
import user from './user'
import essay from './essay'

const smartRevision = {
    auth,
    user,
    essay
}

export default smartRevision
