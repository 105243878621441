import PropTypes from 'prop-types'

import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import { TableRow as TableRowMaterial } from '@material-ui/core'

import styles from './styles'

const TableRow = ({
  children,
  cursor = 'default',
  to,
  height = 'auto',
  ...rest
}) => {
  const useStyles = styles(height, cursor)

  const classes = useStyles()
  const history = useHistory()

  const handleClick = () => {
    if (!to) {
      return
    }

    history.push(to)
  }

  return (
    <TableRowMaterial
      className={clsx(classes.cursor, classes.rowHeight)}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </TableRowMaterial>
  )
}

TableRow.propTypes = {
  pointer: PropTypes.bool,
  to: PropTypes.any,
}

export default TableRow
