import { TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import SaveIcon from '@material-ui/icons/Save'
import { Box, Grid, Button, Divider } from '@material-ui/core'

import { Label } from 'components'

import schema from './schema'
import { useForm, Controller } from 'react-hook-form'
import * as services from 'services'

const EssayUpdateGrade = ({ essayId }) => {
  const history = useHistory()
  const updateGrade = async (values) => {
    await services.smartRevision.essay.update({
      essayId,
      grade: parseFloat(values.grade),
      comment: values.comment,
    })

    history.goBack()
  }

  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: { grade: 0, comment: '' },
  })

  return (
    <Box width="100%">
      <Box px={2} py={3}>
        <Grid container spacing={5} alignItems="center">
          <Divider />
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <form onSubmit={handleSubmit(updateGrade)}>
              <Label align="center" title="Atualizar Nota: " titleVariant="h6">
                <Controller
                  as={
                    <TextField
                      color="secondary"
                      TableEssay
                      type="number"
                      error={!!errors?.grade}
                      helperText={errors?.grade?.message}
                      style={{ backgroundColor: '#fff' }}
                    />
                  }
                  name="grade"
                  control={control}
                  mode="onChange"
                />
              </Label>
              <Label
                style={{ paddingTop: '20px' }}
                align="center"
                title="Observação/Justificativa para a nota: "
                titleVariant="h6"
              >
                <Controller
                  as={
                    <TextField
                      label="Observação/Justificativa"
                      placeholder="Digite aqui uma justificativa para a nota caso exista"
                      color="secondary"
                      rows={8}
                      TableEssay
                      type="text"
                      multiline
                      error={!!errors?.comment}
                      helperText={errors?.comment?.message}
                      style={{ backgroundColor: '#fff', width: '800px' }}
                    />
                  }
                  name="comment"
                  control={control}
                  mode="onChange"
                />
              </Label>

              <Box py={2} textAlign="center">
                <Button
                  justifyContent="center"
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  Atualizar
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default EssayUpdateGrade
